<template>
  <div class="flex flex-wrap mt-4 justify-center">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded w-1/2">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
        <div class="rounded-t mb-0 px-4 py-3 border-0" style="background-color: #F7FAFC;">
          <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 class="font-semibold text-lg text-blueGray-700">
                Gebruikers
              </h3>
            </div>
          </div>
        </div>
        <div class="block w-full bg-white overflow-x-auto px-4 py-3">
          <p class="text-blueGray-600 text-sm leading-relaxed">
            Beheer hier de gebruikers in je groep. Een gebruiker zal toegang hebben tot alle resultaten binnen je groep.<br/>Je kan onder de tabel met bestaande gebruikers een nieuwe gebruiker aanmaken.
          </p>
        </div>
      </div>

      <div class="block mb-6 w-full overflow-x-auto shadow-lg">
        <!-- Users table -->
        <table class="items-center w-full border-collapse">
          <thead>
            <tr>
              <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Gebruikersnaam
              </th>
              <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                E-mailadres
              </th>
              <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Groep
              </th>
              <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              </th>
              <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.user_id" class="hoverable-row bg-white hover:bg-blueGray-100 transition-colors duration-150">
              <td class="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ user.username }}
              </td>
              <td class="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ user.email }}
              </td>
              <td class="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ user.group }}
              </td>
              <td class="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                <button @click="editUser(user)" class="text-white bg-teal-500 hover:bg-teal-700 font-bold py-1 px-3 rounded inline-flex items-center">
                  <i class="fas fa-edit"></i>
                </button>
              </td>
              <td>
                <button v-if="user.username !== currentUserUsername" @click="deleteUser(user.user_id)" class="text-white bg-red-500 hover:bg-red-700 font-bold py-1 px-3 rounded inline-flex items-center">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div 
        class="rounded-t mb-0 px-4 py-3 border-0 cursor-pointer flex justify-between items-center" 
        style="background-color: #F7FAFC;"
        @click="toggleCollapse"
      >
        <h3 class="font-semibold text-lg text-blueGray-700">
          Nieuwe gebruiker aanmaken
        </h3>
        <span>{{ isCollapsed ? '▼' : '▲' }}</span>
      </div>

      <transition 
        name="expand"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave">
        <div v-show="!isCollapsed" class="block bg-white w-full overflow-hidden px-6 py-4" key="form-content">
          <form @submit.prevent="addUser" class="flex flex-wrap mb-4 justify-between">
            <div class="flex flex-wrap mb-4 justify-between w-full gap-4">
              <div style="margin-right: 20px;" class="flex-1 min-w-[calc(50%-1rem)]">
                <input style="margin: 20px;" type="text" v-model="username" placeholder="Gebruikersnaam" class="form-input mb-3">
                <input style="margin: 20px;" type="password" v-model="password" placeholder="Paswoord" class="form-input mb-3">
              </div>
              <div style="margin-right: 20px;" class="flex-1 min-w-[calc(50%-1rem)]">
                <input style="margin: 20px;" type="email" v-model="email" placeholder="E-mail" class="form-input mb-3">
                <input
                  style="margin: 20px;"
                  type="text"
                  v-model="group"
                  placeholder="Groep"
                  class="form-input mb-3"
                >
              </div>
            </div>
            <button type="submit" class="submit-button">
              <i class="fas fa-plus"></i> Add
            </button>
          </form>
        </div>
      </transition>
    </div>

    <CardAlert
      v-show="alertMessage"
      ref="cardAlert"
      :message="alertMessage"
      @close="alertMessage = 'Hello'"
    />

    <ReusableModal :isVisible="showEditModal" @close="showEditModal = false">
      <template v-slot:header>
        <h2 class="modal-header">Edit User</h2>
      </template>
      
      <template v-slot:body>
        <form @submit.prevent="submitForm" class="modal-form">
          <div class="form-group">
            <label for="userEmail" class="form-label">Email:</label>
            <input id="userEmail" type="email" v-model="user.email" required class="form-input">
          </div>
          <div class="form-group">
            <label for="userGroup" class="form-label">Groep:</label>
            <input id="userGroup" type="text" v-model="user.group" required class="form-input">
          </div>
        </form>
      </template>

      <template v-slot:footer>
        <button @click="submitForm" class="btn btn-save">Save</button>
        <button @click="showEditModal = false" class="btn btn-cancel">Cancel</button>
      </template>
    </ReusableModal>
  </div>
</template>


<script>
import axios from 'axios';
import CardAlert from '@/components/Cards/CardAlert.vue';
import ReusableModal from '@/components/Cards/ReusableModal.vue';

export default {
  components: {
    CardAlert,
    ReusableModal,
  },
  data() {
    return {
      user: {
        email: '',
        group: '',  // Changed from organization to group
      },
      users: [],
      username: '',
      password: '',
      email: '',
      group: '',  // Changed from organization to group
      isCollapsed: true,
      alertMessage: '',
      validationErrors: [],
      isSuperUser: false,
      showEditModal: false
    };
  },
  mounted() {
    this.getCurrentUser();
    this.fetchUsers();
    this.getCurrentUserDetails();
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    beforeEnter(el) {
      el.style.maxHeight = '0';
    },
    enter(el) {
      el.style.maxHeight = el.scrollHeight + 'px';
    },
    beforeLeave(el) {
      el.style.maxHeight = el.scrollHeight + 'px';
    },
    leave(el) {
      el.style.maxHeight = '0';
    },
    addUser() {
      this.validationErrors = []; // Reset validation errors

      // Check each field and add errors if they are empty
      if (!this.username) this.validationErrors.push("Gebruikersnaam");
      if (!this.password) this.validationErrors.push("Paswoord");
      if (!this.email) this.validationErrors.push("E-mail");
      if (!this.group) this.validationErrors.push("Groep");  // Changed from organization to group

      // If there are validation errors, show the alert and stop the form submission
      if (this.validationErrors.length > 0) {
        this.alertMessage = `Vul volgende velden in: ${this.validationErrors.join(", ")}.`;
        this.$refs.cardAlert.showAlert();
        return; // Stop the form submission
      }

      const userData = {
        username: this.username,
        password: this.password,
        email: this.email,
        group: this.group,  // Changed from organization to group
      };

      const token = localStorage.getItem('user-token');
      axios.post('https://conan.antsolutions.be/conanbe/create_user/', userData, {
        headers: { Authorization: `Token ${token}` }
      })
        .then(response => {
          console.log(response);
          this.alertMessage = 'Gebruiker aangemaakt!';
          this.$refs.cardAlert.showAlert();
          // Reset form fields
          this.username = '';
          this.password = '';
          this.email = '';
          this.group = '';  // Changed from organization to group
          this.fetchUsers();  // Refresh the list of users
        })
        .catch(error => {
          this.alertMessage = 'Failed to create user. Please try again: ' + error;
          this.$refs.cardAlert.showAlert();
        });
    },
    fetchUsers() {
      const token = localStorage.getItem('user-token');
      axios.get('https://conan.antsolutions.be/conanbe/getAllUsers/', {
        headers: { Authorization: `Token ${token}` }
      })
      .then(response => {
        this.users = response.data;
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
    },
    getCurrentUser() {
      this.currentUserUsername = localStorage.getItem('user-username');
    },
    deleteUser(user_id) {
      const token = localStorage.getItem('user-token');
      axios.delete(`https://conan.antsolutions.be/conanbe/deleteUser/${user_id}/`, {
        headers: { Authorization: `Token ${token}` }
      })
      .then(response => {
        console.log(response);
        this.alertMessage = 'Gebruiker werd verwijderd.'
        this.$refs.cardAlert.showAlert();
        this.fetchUsers();  // Refresh the list of users
      })
      .catch(error => {
        alert('Failed to delete user: ' + error);
      });
    },
    editUser(selectedUser) {
      // Populate the user object with the details of the user you want to edit
      this.user.email = selectedUser.email;
      this.user.group = selectedUser.group;  // Changed from organization to group
      // Show the modal
      this.showEditModal = true;
    },
    getCurrentUserDetails() {
      const token = localStorage.getItem('user-token');
      axios.get('https://conan.antsolutions.be/conanbe/getCurrentUserDetails/', {
        headers: { Authorization: `Token ${token}` }
      })
      .then(response => {
        this.isSuperUser = response.data.is_superuser;
      })
      .catch(error => {
        console.error('Error fetching user details:', error);
      });
    },
  }
};
</script>


<style>
.form-input {
  border: 0;
  padding: 0.5rem 0.75rem;
  color: #4B5563; /* Assuming blueGray-600 */
  background-color: white;
  border-radius: 0.375rem; /* Assuming a border-radius of 6px */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 1rem; /* Adds space between inputs */
  transition: all 0.15s ease;
}

/* For placeholder color, you might need to target specific browsers */
.form-input::placeholder {
  color: #CBD5E0; /* Assuming blueGray-300 */
}

.submit-button {
  display: block; /* To make the button take the full width */
  color: white;
  background-color: #10B981;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem; /* Assuming text-xs */
  padding: 0.5rem 1rem; /* px-4 py-2 */
  border-radius: 0.375rem; /* Assuming a border-radius of 6px */
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 1rem; /* Space above the button */
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
}

.submit-button:hover,
.submit-button:focus {
  background-color: #0F766E; /* A darker green for hover/focus */
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
}

.submit-button:active {
  background-color: #065F46; /* Even darker green for the active state */
}

.expand-enter-active, .expand-leave-active {
  transition: max-height 0.5s ease-in-out;
}
.expand-enter, .expand-leave-to /* .expand-leave-active in <2.1.8 */ {
  max-height: 0;
}
.modal-header {
  margin: 0;
  color: #333;
  font-size: 24px;
  font-weight: 600;
}

.modal-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  color: #666;
  font-size: 16px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}

.btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  border: none;
  margin-right: 10px;
}

.btn-save {
  background-color: #4CAF50;
  color: white;
}

.btn-save:hover {
  background-color: #45a049;
}

.btn-cancel {
  background-color: #f44336;
  color: white;
}

.btn-cancel:hover {
  background-color: #da190b;
}
</style>