import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";


// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import Maps from "@/views/admin/Maps.vue";

// views for Admin/RMTool layout

import RMTAnalysis from "@/views/admin/RMTAnalysis.vue";
import RMTUpload from "@/views/admin/RMTUpload.vue";
import RMTCollections from "@/views/admin/RMTCollections.vue";
import UserManagement from "@/views/admin/UserManagement.vue"

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import PasswordReset from "@/views/auth/PasswordReset.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";

// routes

const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/settings",
        component: Settings,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/tables",
        component: Tables,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/maps",
        component: Maps,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/users",
        component: UserManagement,
      },
    ],
  },
  {
    path: '/public/result/:batchId',
    name: 'SharedRMTAnalysis',
    component: () => import('@/views/public/SharedRMTAnalysis.vue'),
    props: true
  },
  
  {
    path: "/admin/rmtool",
    redirect: "/admin/rmtool/analysis",
    component: Admin,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/admin/rmtool/uploadresults",
        component: RMTUpload,
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/rmtool/result/:batchId',
        name: 'result',
        component: RMTAnalysis,
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: '/admin/rmtool/collections',
        name: 'collections',
        component: RMTCollections,
        meta: { requiresAuth: true },
        props: true
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      },
      {
        path: "/auth/reset-password", // Add this route for password reset
        component: PasswordReset,
      },
    ],
  },
  {
    path: "/landing",
    component: Landing,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    redirect: "/admin/tables",
    component: Index,
    meta: { requiresAuth: true },
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user-token') == null) {
      next({
        path: '/auth/login',
        params: { nextUrl: to.fullPath }
      });
    } else {
      let user = JSON.parse(localStorage.getItem('user'))
      if (to.matched.some(record => record.meta.is_admin)) {
        if (user.is_admin == 1) {
          next();
        } else {
          next({ name: 'userboard' });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('user-token') == null) {
      next();
    } else {
      next({ name: 'userboard' });
    }
  } else {
    next();
  }
});

createApp(App).use(router).mount("#app");