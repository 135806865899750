<template>
  <div id="analysis" :class="customClasses" :style="customStyles">
    <div class="w-full mb-12 px-4">
      <div>
        <div class="px-4 md:px-10 mx-auto w-full">
          <div>
            <!-- Card stats -->
            <div id="textCardLine1" class="flex flex-wrap" style="margin-bottom: 50px">
              <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
                <card-stats
                  v-if="showDocTypesCard"
                  :loading="loading"
                  statSubtitle="Totale omvang"
                  :statTitle="totalSize.toString()"
                  :statPercent="TotalSizeDiff"
                  statDescripiron="sinds de laatste controle"
                  statIconName="far fa-hard-drive"
                  statIconColor="bg-emerald-500"
                  modal-id="auditModal"
                  :resultObject="auditObject"
                  modalTitle="Details (Audit)"
                  :cleanupSuggestion="cleanupSuggestion.totalSize"
                />
              </div>
              <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
                <card-stats
                  v-if="showAuditCard"
                  :loading="loading"
                  statSubtitle="Aantal mappen"
                  :statTitle="totalCheckedMaps.toString()"
                  :statPercent="TotalSizeDiff"
                  statDescripiron="sinds de laatste controle"
                  statIconName="fas fa-folder"
                  statIconColor="bg-orange-500"
                  modal-id="auditModal"
                  :resultObject="auditObject"
                  modalTitle="Details (Audit)"
                  :cleanupSuggestion="cleanupSuggestion.totalCheckedMaps"
                />
              </div>
              <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
                <card-stats
                  v-if="showAuditCard"
                  :loading="loading"
                  statSubtitle="Aantal documenten"
                  :statTitle="totalCheckedDocs.toString()"
                  :statPercent="TotalDocsDiff"
                  statDescripiron="sinds de laatste controle"
                  statIconName="fas fa-file"
                  statIconColor="bg-pink-500"
                  modal-id="auditModal"
                  :resultObject="auditObject"
                  modalTitle="Details (Audit)"
                  :cleanupSuggestion="cleanupSuggestion.totalCheckedDocs"
                />
              </div>
            </div>
          </div>
        </div>
        <div id="documentAgeChart" v-if="lineChartData && lineChartData.labels.length">
          <ReusableCardLineChart
            v-if="showOldNewFoldersCard"
            :loading="loading"
            :chartData="lineChartData"
            chartTitle="Oudste document"
            chartSubtitle="Aantal mappen gewijzigd per jaar"
          />
        </div>
        <div v-else>
          
        </div>
      </div>
      <div id="textCardLine2" class="flex flex-wrap" style="margin-top: 50px; margin-bottom: 50px">
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            v-if="showIDDocsCard"
            statSubtitle="Identieke documenten"
            :statTitle="totalIDDocs.toString()"
            :statPercent="IDDocContentDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-copy"
            statIconColor="bg-red-500"
            modal-id="idDocsModal"
            :resultObject="IDDocsObject"
            modalTitle="Details (Identieke documenten)"
            :cleanupSuggestion="cleanupSuggestion.totalIDDocs"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            v-if="showIDDocsCard"
            statSubtitle="Documenten met identieke naam"
            :statTitle="totalIDNameDocs.toString()"
            :statPercent="IDDocNameDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-copy"
            statIconColor="bg-red-500"
            modal-id="idDocsModal"
            :resultObject="IDDocsObject"
            modalTitle="Details (Identieke documenten)"
            :cleanupSuggestion="cleanupSuggestion.totalIDNameDocs"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            v-if="showIDDocsCard"
            statSubtitle="Docs met identieke inhoud & naam"
            :statTitle="(completelyIDDocs.length - 1).toString()"
            :statPercent="CompletelyIDDocsDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-copy"
            statIconColor="bg-red-500"
            modal-id="idDocsModal"
            :resultObject="IDDocsObject"
            modalTitle="Details (Identieke documenten)"
            :cleanupSuggestion="cleanupSuggestion.completelyIDDocs"
          />
        </div>
      </div>
      <div id="pieChartLine1" class="flex w-full items-stretch">
        <div class="w-6/12 p-2">
          <ReusableCardPieChart
            v-if="showDocTypesCard"
            :loading="loading"
            :chartData="documentPieData"
            :alternateChartData="alternatePieData"
            chartTitle="Documenten per extensie"
            chartSubtitle="Documenttypes (top 10 aantal)"
            alternateChartSubtitle="Documenttypes (top 10 grootte in KB)"
            @segmentClicked="handleSegmentClick"
          />
        </div>
        <div class="w-6/12 p-2">
          <ReusableCardPieChart
            v-if="showZipFilesCard"
            :loading="loading"
            :chartData="pieData"
            chartTitle="Uitgepakt, deels uitgepakt, volledig uitgepakt"
            chartSubtitle="ZIP-bestanden"
            @segmentClicked="handleSegmentClick"
          />
        </div>
      </div>
      <div id="textCardLine3" class="flex flex-wrap" style="margin-top: 50px; margin-bottom: 50px">
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            v-if="showCleanMapsCard"
            statSubtitle="Corrupte documenten"
            :statTitle="totalCorruptDocs"
            :statPercent="CorruptDocsDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-file-excel"
            statIconColor="bg-black"
            modal-id="cleanMapsModal"
            :resultObject="cleanMapsObject"
            modalTitle="Details (Schonen van mappen)"
            :cleanupSuggestion="cleanupSuggestion.totalCorruptDocs"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            v-if="showCleanMapsCard"
            statSubtitle="Lege mappen"
            :statTitle="totalEmptyMaps"
            :statPercent="EmptyMapsDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-folder-open"
            statIconColor="bg-teal-500"
            modal-id="cleanMapsModal"
            :resultObject="cleanMapsObject"
            modalTitle="Details (Schonen van mappen)"
            :cleanupSuggestion="cleanupSuggestion.totalEmptyMaps"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            v-if="showCleanMapsCard"
            statSubtitle="Identieke naam, ander formaat"
            :statTitle="totalIDDocsDifferentFormat"
            :statPercent="IDDocDiffFormatDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-clone"
            statIconColor="bg-purple-500"
            modal-id="cleanMapsModal"
            :resultObject="cleanMapsObject"
            modalTitle="Details (Schonen van mappen)"
            :cleanupSuggestion="cleanupSuggestion.totalIDDocsDifferentFormat"
          />
        </div>
      </div>
      <div id="unsupportedFormatsChart" v-if="chartData">
        <ReusableCardBarChart
          v-if="showCleanMapsCard"
          :loading="loading"
          :chartData="chartData"
          chartTitle="Schonen van mappen"
          chartSubtitle="Niet-ondersteunde formaten"
        />
      </div>
      <div id="textCardLine4" class="flex flex-wrap" style="margin-top: 50px; margin-bottom: 50px">
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            v-if="showLastTextCards"
            statSubtitle="Verkeerde extensie"
            :statTitle="totalWrongExtension"
            :statPercent="WrongExtensionDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-file-excel"
            statIconColor="bg-orange-500"
            modal-id="wrongExtensionModal"
            :resultObject="wrongExtensionsObject"
            modalTitle="Details (Foutieve extensies)"
            :cleanupSuggestion="cleanupSuggestion.totalWrongExtension"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            v-if="showLastTextCards"
            statSubtitle="Ongeldige snelkoppelingen"
            :statTitle="totalWrongShortcuts"
            :statPercent="WrongShortcutsDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-share-square"
            statIconColor="bg-red-500"
            modal-id="shortcutsModal"
            :resultObject="shortcutsObject"
            modalTitle="Details (Snelkoppelingen)"
            :cleanupSuggestion="cleanupSuggestion.totalWrongShortcuts"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            v-if="showLastTextCards"
            statSubtitle="Beveiligd met wachtwoord"
            :statTitle="totalPasswordProtected"
            :statPercent="ProtectedwPassDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-window-close"
            statIconColor="bg-teal-500"
            modal-id="protectedModal"
            :resultObject="protectedObject"
            modalTitle="Details (Beveiligde documenten)"
            :cleanupSuggestion="cleanupSuggestion.totalPasswordProtected"
          />
        </div>
      </div>
      <div id="donutChartLine1" class="flex w-full items-stretch">
        <div class="w-6/12 p-2">
          <ReusableCardDonutChart
            :loading="loading"
            v-if="showCleanMapsCard"
            :chartData="systemFormatsChartData"
            chartTitle="Schonen van mappen"
            chartSubtitle="Systeembestanden"
            @segmentClicked="handleSegmentClick"
          />
        </div>
        <div class="w-6/12 p-2">
          <ReusableCardDonutChart
            :loading="loading"
            v-if="showCleanMapsCard"
            :chartData="templateFormatsChartData"
            chartTitle="Schonen van mappen"
            chartSubtitle="Sjabloonbestanden"
            @segmentClicked="handleSegmentClick"
          />
        </div>
      </div>
      <div id="donutChartLine2" class="flex w-full items-stretch">
        <div class="w-6/12 p-2">
          <ReusableCardDonutChart
            :loading="loading"
            v-if="showCleanMapsCard"
            :chartData="compressionFormatsChartData"
            chartTitle="Schonen van mappen"
            chartSubtitle="Compressiebestanden"
            @segmentClicked="handleSegmentClick"
          />
        </div>
        <div class="w-6/12 p-2">
          <ReusableCardDonutChart
            :loading="loading"
            v-if="showCleanMapsCard"
            :chartData="backupFormatsChartData"
            chartTitle="Schonen van mappen"
            chartSubtitle="Backupbestanden"
            @segmentClicked="handleSegmentClick"
          />
        </div>
      </div>
      <div v-if="!public_url">
      <div class="flex justify-end">
        <button
          v-if="!loading"
          style="margin-right: 5px;"
          @click="exportChartsToPDF"
          class="flex items-center justify-center bg-red-500 text-white font-semibold px-4 py-2 rounded shadow-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50 transition ease-in duration-200"
        >
          <i class="fas fa-file-pdf mr-2"></i> <!-- Ensure you're using the correct Font Awesome class -->
          Exporteer naar PDF
        </button>
        <button style="margin-left: 5px;" class="flex items-center justify-center bg-red-500 text-white font-semibold px-4 py-2 rounded shadow-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50 transition ease-in duration-200" @click="togglePublicShare(batchId)">
          <i class="fas fa-globe mr-2"></i>{{ public_share ? 'Publieke link deactiveren' : 'Publieke link delen' }}
        </button>
      </div>
      <div v-if="public_share" class="shareable-url-container flex justify-between items-center p-4 bg-gray-100 rounded-lg shadow-md mx-auto">
    <!-- Shareable URL Text -->
    <p class="text-blue-600 font-semibold truncate">{{ shareableUrl }}</p>
    
    <!-- Copy Button -->
    <button @click="copyToClipboard" class="p-2 ml-4 bg-red-500 text-white rounded-full shadow hover:bg-blue-700 transition duration-200">
      <i class="fa fa-copy"></i> <!-- FontAwesome copy icon -->
    </button>
  </div>
  </div>
    </div>
  </div>
</template>
<script>
import 'bootstrap';
import axios from 'axios';
import ReusableCardBarChart from "@/components/Cards/ReusableCardBarChart.vue";
import ReusableCardLineChart from "@/components/Cards/ReusableCardLineChart.vue";
import ReusableCardPieChart from "@/components/Cards/ReusableCardPieChart.vue";
import ReusableCardDonutChart from "@/components/Cards/ReusableCardDonutChart.vue";
import CardStats from "@/components/Cards/CardStats.vue";
import html2canvas from 'html2canvas';
import logo from '@/assets/img/conan.png';
import { jsPDF } from 'jspdf';
//import ReusableCardTable from "@/components/Cards/ReusableCardTable.vue";

// Helper function to convert Excel date serial number to JavaScript Date object.
// Excel date serial numbers are the number of days since January 0, 1900
function excelSerialDateToJSDate(serial) {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400; 
  return new Date(utc_value * 1000);
}

export default {
  props: {
    customClasses: {
      type: String,
      default: 'flex flex-wrap mt-4'
    },
    customStyles: {
      type: Object,
      default: () => ({})
    },
    batchId: {
      type: String,
      required: true
    },
    public_url: {
      type: Boolean,
      required: false
    }
  },
  components: {
    ReusableCardBarChart,
    ReusableCardPieChart,
    ReusableCardLineChart,
    ReusableCardDonutChart,
    CardStats,
    // ReusableCardTable,
  },
  data() {
    return {
      showDocTypesCard: false,
      showAuditCard: false,
      showIDDocsCard: false,
      showOldNewFoldersCard: false,
      showCleanMapsCard: false,
      showZipFilesCard: false,
      showLastTextCards: false,
      logoSrc: '/img/conan.png',
      batchData: null,
      processedBatchData: null,
      documentTypesObject: {},
      IDDocsObject: {},
      oldNewMapsObject: {},
      cleanMapsObject: {},
      ZIPFilesObject: {},
      wrongExtensionsObject: {},
      shortcutsObject: {},
      auditObject: {},
      protectedObject: {},
      chartData: {
      labels: [],
      datasets: [],
      public_share: false,
      shareableUrl: ''
      }, // Declare chartData here
      unsupportedFormatsChartData: {
      labels: [],
      datasets: []
      },
      systemFormatsChartData: {
      labels: [],
      datasets: []
      },
      templateFormatsChartData: {
      labels: [],
      datasets: []
      }, 
      compressionFormatsChartData: {
      labels: [],
      datasets: []
      }, 
      backupFormatsChartData: {
      labels: [],
      datasets: []
      }, 
      IDDocsData: null,
      totalSize: "0",
      totalSizeAccurate: "0",
      totalCheckedMaps: "0",
      totalCheckedDocs: "0",
      auditData: null,
      totalIDDocs: "0",
      totalIDNameDocs: "0",
      completelyIDDocs: [0],
      totalZipFiles: "0",
      totalUnpackedZips: "0",
      totalPackedZips: "0",
      totalPartlyUnpackedZips: "0",
      totalCorruptDocs: "0",
      totalEmptyMaps: "0",
      totalIDDocsDifferentFormat: "0",
      totalWrongExtension: "0",
      totalPasswordProtected: "0",
      totalWrongShortcuts: "0",
      pieData: {
      labels: [],
      datasets: [],
      },
      cleanupSuggestion: [],
      parsedData: [],
      currentTableData: [], // This will hold the data to display in the table
      // Data arrays
      gedeeltelijkuitgepaktezipbes: [], // Fill these with actual data
      nietuitgepaktezipbestanden: [],
      vollediguitgepaktezipbestand: [],
      lineChartData: {
      labels: [],
      datasets: []
      },
      documentPieData: {
      labels: [],
      datasets: []
      },     
      alternatePieData: {
      labels: [],
      datasets: []
      },
      oldTotalSize: 0,
      oldTotalDocs: 0,
      oldTotalMaps: 0,
      oldIDDocName: 0,
      oldIDDocContent: 0,
      oldCompletelyIDDocs: 0,
      oldCorruptDocs: 0,
      oldEmptyMaps: 0,
      oldIDDocDiffFormat: 0,
      oldWrongExtension: 0,
      oldWrongShortcuts: 0,
      oldProtectedwPass: 0,
      TotalSizeDiff: 0,
      TotalDocsDiff: 0,
      TotalMapsDiff: 0,
      IDDocNameDiff: 0,
      IDDocContentDiff: 0,
      CompletelyIDDocsDiff: 0,
      CorruptDocsDiff: 0,
      EmptyMapsDiff: 0,
      IDDocDiffFormatDiff: 0,
      WrongExtensionDiff: 0,
      WrongShortcutsDiff: 0,
      ProtectedwPassDiff: 0,
      loading: true,
    };
  },
  mounted() {
    // Build the shareable URL when the component is mounted
    const currentUrl = window.location.href;
    this.shareableUrl = currentUrl.replace('/admin/rmtool/', '/public/');  // Remove the '/admin/rmtool/' part
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.shareableUrl).then(() => {
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    },
  togglePublicShare(batchId) {
    const token = localStorage.getItem('user-token');
    axios.put(`https://conan.antsolutions.be/conanbe/togglePublicShare/${batchId}/`, {}, {
      headers: {
        Authorization: `Token ${token}`
      }
    })
    .then(response => {
      console.log(response);
      // Handle response, update UI
      //console.log(response.data);
      this.fetchData(batchId);
      //console.log(this.parsedData['public_share']);
    })
    .catch(error => {
      console.error('Error toggling public share:', error);
    });
  },
    async loadLogo() {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = logo; // Use the imported logo
  });
},
    async captureChart(chartElement) {
  try {
    const canvas = await html2canvas(chartElement);
    return canvas.toDataURL('image/png');
  } catch (error) {
    console.error('Error capturing chart:', error);
  }
},
    async exportChartsToPDF() {
      const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: 'a4'
  });

  const margin = 20;
  let pdfWidth = pdf.internal.pageSize.getWidth();
  let pdfHeight = pdf.internal.pageSize.getHeight();
  let pdfYPosition = margin;

  // Load and add logo
  try {
    const logoImg = await this.loadLogo();
    const logoWidth = 100; // Set as needed
    const logoHeight = logoImg.height * (logoWidth / logoImg.width); // Maintain aspect ratio
    const logoX = (pdfWidth - logoWidth) / 2; // Center the logo
    pdf.addImage(logoImg, 'PNG', logoX, pdfYPosition, logoWidth, logoHeight);
    pdfYPosition += logoHeight + margin;
  } catch (error) {
    console.error('Error loading logo:', error);
  }

  // List of chart component selectors (update this with your actual chart IDs or refs)
  const chartSelectors = [
    '#textCardLine1', 
    '#documentAgeChart',
    '#textCardLine2',
    '#pieChartLine1',
    '#textCardLine3',
    '#unsupportedFormatsChart',
    '#textCardLine4',
    '#donutChartLine1',
    '#donutChartLine2'
  ];

  for (let selector of chartSelectors) {
    const chartElement = document.querySelector(selector);
    if (chartElement) {
      const chartImage = await this.captureChart(chartElement);
      if (!chartImage) {
        //console.log(`No image captured for chart: ${selector}, skipping...`);
        continue; // Skip if no image was captured
      }

      let img = new Image();
      img.src = chartImage;
      await new Promise(resolve => { img.onload = resolve });

      let canvasAspectRatio = img.width / img.height;
      let imgWidth = pdfWidth - (2 * margin); // Adjust width for margins
      let imgHeight = imgWidth / canvasAspectRatio;

      if (pdfYPosition + imgHeight > pdfHeight - margin) {
        pdf.addPage();
        pdfYPosition = margin; // Reset Y position at the top of the new page
      }

      pdf.addImage(chartImage, 'PNG', margin, pdfYPosition, imgWidth, imgHeight);
      pdfYPosition += imgHeight + margin; // Update Y position for the next chart
    } else {
      //console.log(`Chart element not found for selector: ${selector}`);
    }
  }

  pdf.save('charts_analysis.pdf');
},


// // Helper function to load an image and return it as a data URL
// // Helper function to load an image and return it as a data URL
// async loadImage() {
//   const url = "https://conan.solutions/wp-content/uploads/2023/09/imageedit_8_5860604835-300x97.png"
//   //console.log(url);
//   return new Promise((resolve, reject) => {
//     let img = new Image();
//     img.crossOrigin = 'Anonymous';
//     img.onload = () => resolve(img);
//     img.onerror = reject;
//     img.src = url;
//   }).then(img => {
//     // Create a canvas to convert the image to data URL format
//     let canvas = document.createElement('canvas');
//     canvas.width = img.width;
//     canvas.height = img.height;
//     let ctx = canvas.getContext('2d');
//     ctx.drawImage(img, 0, 0);
//     return canvas.toDataURL('image/png');
//   });
// },



  fetchData(batchId) {
    const token = localStorage.getItem('user-token');
    //console.log(token);
    axios.get(`https://conan.antsolutions.be/conanbe/fetchExcelResultById/${batchId}/`, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'multipart/form-data'
        }})
      .then(response => {
        this.loading = false;
        //console.log(response.data);
        let parsedData;
        const dataAsStringOrObject = response.data;

        // Check if the response is already parsed as an object
        if (typeof dataAsStringOrObject === 'string') {
          // If it's a string, perform the NaN replacement and parse it
          //console.log(dataAsStringOrObject);
          const correctedDataString = dataAsStringOrObject.replace(/\bNaN\b/g, '"NaN"');
          parsedData = JSON.parse(correctedDataString);
          this.parsedData = parsedData;
        } else {
          // If it's already an object, simply use it directly
          //console.log(dataAsStringOrObject);
          parsedData = dataAsStringOrObject;
        }
        this.batchData = parsedData.data;
        //console.log(parsedData.public_share);
        if (parsedData.public_share){
          this.public_share=true;
        }
        else {
          this.public_share=false;
        }

        // Reset flags before processing the data
        this.showDocTypesCard = false;
        this.showAuditCard = false;
        this.showIDDocsCard = false;
        this.showOldNewFoldersCard = false;
        this.showCleanMapsCard = false;
        this.showZipFilesCard = false;
        this.showLastTextCards = false;

        // Check if batchData is an array
        if (Array.isArray(this.batchData)) {
          console.log(this.batchData);
          //console.log(this.batchData);

          // Iterate over the array and extract data for each file by filename
          this.batchData.forEach(item => {
            const filename = Object.keys(item)[0]; // Get the filename (e.g., "DocTypes.xlsx")
            const fileData = item[filename]; // Get the corresponding data for the filename

            // Process based on filename
            if (filename === "DocTypes.xlsx" && fileData) {
              //console.log("doctypes found");
              this.generateDocTypeChart(fileData);
              this.showDocTypesCard = true;  // Set the flag to show the card
            }
            if (filename === "Audit.xlsx" && fileData) {
              //console.log("audit found");
              this.generateAuditData(fileData);
              this.showAuditCard = true;  // Set the flag to show the card
            }
            if (filename === "IdDocs.xlsx" && fileData) {
              //console.log("iddocs found");
              this.generateIDDocData(fileData);
              this.showIDDocsCard = true;  // Set the flag to show the card
            }
            if (filename === "OldNewFolders.xlsx" && fileData) {
              //console.log("oldnewfolders found");
              this.generateFoldersLastChangedPerYearLineChart(fileData);
              this.showOldNewFoldersCard = true;  // Set the flag to show the card
            }
            if (filename === "CleanFolders.xlsx" && fileData) {
              //console.log("cleanmaps found");
              this.generateUnsupportedFileFormatsChart(fileData);
              this.generateCleanMapsData(fileData);
              this.generateSystemFormatsChart(fileData);
              this.generateTemplateFormatsChart(fileData);
              this.generateCompressionFormatsChart(fileData);
              this.generateBackupFormatsChart(fileData);
              this.showCleanMapsCard = true;  // Set the flag to show the card
            }
            if (filename === "ZIPFiles.xlsx" && fileData) {
              //console.log("zipfiles found");
              this.generateZipData(fileData);
              this.showZipFilesCard = true;  // Set the flag to show the card
            }
            if ((filename === "SecureDocs.xlsx" || filename === "FileFormats.xlsx" || filename === "Shortcuts.xlsx") && fileData) {
              this.generateLastTextCardsData(this.batchData);
              this.showLastTextCards = true;  // Set the flag to show the card
            }
            setTimeout(() => {
              this.generateCleanupSuggestions();
            }, 1000);
          });
        } else {
          console.warn('batchData is not an array or is empty.');
        }

        // Generate percentage differences if last_batch_data_points exists
        if (parsedData.last_batch_data_points) {
          this.generatePercentageDifferences(parsedData.last_batch_data_points);
        } else {
          console.warn('Data for generatePercentageDifferences is missing.');
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  },


    round(value, decimals) {
      return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    },
        // Function to find the value based on the key in overzicht
findValueByKey(overzicht, searchKey) {
    // Use the find method to locate the object where key 0 matches the searchKey
    const result = overzicht.find(item => item[0] === searchKey);
    
    // Return the value corresponding to key 1 or undefined if not found
    return result ? result[1] : undefined;
},
    generateUnsupportedFileFormatsChart(data) {
  // Extracting the data
  console.log(data);
  const fileData = data['nietondersteundeformaten'].slice(1); // Assuming first row is header
if (fileData){
  // Function to extract file extension
  const extractExtension = (filePath) => {
    const parts = filePath.split('.');
    return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : null;
  };

  // Object to store count of each extension
  let extensionCounts = {};

  fileData.forEach(item => {
    const extension = extractExtension(item[0]);
    if (extension) {
      extensionCounts[extension] = (extensionCounts[extension] || 0) + 1;
    }
  });

  // Convert to array and sort by count
  const sortedExtensions = Object.entries(extensionCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 30); // Selecting top 30

  // Splitting into labels and data for the chart
  const labels = sortedExtensions.map(ext => ext[0]);
  const dataCounts = sortedExtensions.map(ext => ext[1]);

  // Create the chart data structure
  const chartData = {
    labels: labels,
    datasets: [{
      label: 'Aantal documenten',
      backgroundColor: '#4dc9f6',
      borderColor: '#4dc9f6',
      data: dataCounts,
      fill: false,
    }]
  };

  this.chartData = chartData;
}
    },
    generateFoldersLastChangedPerYearLineChart(folderData) {
      let foldersPerYear = {};

      const foldersData = folderData['oudemappen'].slice(1); // Skip header row

      for (const folder of foldersData) {
        let year = excelSerialDateToJSDate(folder[1]).getFullYear();
        if (!foldersPerYear[year]) {
          foldersPerYear[year] = 0;
        }
        foldersPerYear[year]++;
      }

      const labels = Object.keys(foldersPerYear).sort();
      const chartDataValues = labels.map(year => foldersPerYear[year]);

      const chartData = {
        labels: labels,
        datasets: [{
          label: 'Laatste mapwijzigingen per jaar',
          backgroundColor: "#4dc9f6",
          borderColor: "#4dc9f6",
          data: chartDataValues,
          fill: false,
        }],
      };
      this.lineChartData = chartData;
      //console.log(this.lineChartData.labels.length);
      //console.log(this.lineChartData);
    },
    generateIDDocData(data){
      // Access the nested data within the first object of the array
      //console.log(data);
      this.totalIDDocs = this.IDDocsData = data['overzicht'][5][1];
      this.totalIDNameDocs = this.IDDocsData = data['overzicht'][6][1];
      this.IDDocsObject = data;

      //console.log(data['identiekedocumentnaam']);
      var concatIDDocNames = data['identiekedocumentnaam'].map(obj => obj[0] + obj[1]).slice(1);
      var concatIDDocs = data['identiekeinhoud'].map(obj => obj[0] + obj[1]).slice(1);
      this.completelyIDDocs = concatIDDocNames.filter(value => concatIDDocs.includes(value));
      //console.log(concatIDDocNames);
      //console.log(concatIDDocs);
      //console.log(this.completelyIDDocs);
      //console.log(this.IDDocsData);
    },
    generateAuditData(data){
      // Access the nested data within the first object of the array
      //console.log(data['overzicht'][2][1]);
      this.auditObject = data;
      this.totalCheckedMaps = data['overzicht'][2][1];
      this.totalCheckedDocs = data['overzicht'][3][1];
      //console.log(this.IDDocsData);
    },
    generateCleanupSuggestions() {
  // Suggestions for totalSize based on its value and comparisons with other metrics
  if (this.TotalSizeDiff > 0) {
    this.cleanupSuggestion.totalSize = `Je totale opslag is gegroeid met ${this.TotalSizeDiff}% sinds de laatste controle. Controleer of de nieuwe documenten relevant zijn, en overweeg oude of ongebruikte bestanden te verwijderen om ruimte vrij te maken.`;
  } else if (this.TotalSizeDiff < 0) {
    this.cleanupSuggestion.totalSize = `Je totale opslag is gekrompen met ${Math.abs(this.TotalSizeDiff)}% sinds de laatste controle. Goed gedaan! Blijf ruimte besparen door overbodige bestanden te verwijderen.`;
  } else {
    this.cleanupSuggestion.totalSize = `Dit is je eerste controle dus er kan moeilijk een suggestie gegeven worden op basis van de grootte. Hou je opslagruimte in het oog door overbodige bestanden te verwijderen! `;
  }

  // Cleanup suggestion for totalCheckedMaps
  if (this.TotalMapsDiff > 0) {
    this.cleanupSuggestion.totalCheckedMaps = "Het aantal gecontroleerde mappen is toegenomen. Zorg ervoor dat de mappen goed georganiseerd blijven.";
  } else if (this.TotalMapsDiff < 0) {
    this.cleanupSuggestion.totalCheckedMaps = "Het aantal gecontroleerde mappen is afgenomen. Controleer of je mappen hebt verwijderd of samengevoegd.";
  } else if (this.TotalMapsDiff == 0) {
    this.cleanupSuggestion.totalCheckedMaps = "Het is je eerste controle dus het aantal mappen kan op zich nog moeilijk beoordeeld worden. Hou de groei in de gaten!";
  } else {
    this.cleanupSuggestion.totalCheckedMaps = "Het aantal gecontroleerde mappen is stabiel sinds de laatste controle.";
  }

  // Relative suggestions between totalCheckedMaps and totalCheckedDocs
  if (parseInt(this.totalCheckedMaps) > 500 && parseInt(this.totalCheckedDocs) < 1000) {
    this.cleanupSuggestion.totalCheckedMaps += " Je hebt relatief veel mappen voor het aantal documenten. Overweeg om sommige mappen samen te voegen.";
  } else if (parseInt(this.totalCheckedMaps) < 100 && parseInt(this.totalCheckedDocs) > 10000) {
    this.cleanupSuggestion.totalCheckedMaps += " Je hebt een klein aantal mappen met een groot aantal documenten. Misschien zijn er mappen waar te veel documenten in zitten, overweeg om submappen aan te maken.";
  } else {
    this.cleanupSuggestion.totalCheckedMaps += " Het aantal mappen lijkt goed in verhouding te zijn met het aantal documenten.";
  }

  // Additional suggestion based on specific thresholds for organizing
  if (parseInt(this.totalCheckedMaps) > 1000) {
    this.cleanupSuggestion.totalCheckedMaps += " Je hebt een groot aantal mappen. Overweeg om je mappenstructuur te herzien voor betere navigatie en beheer.";
  } else if (parseInt(this.totalCheckedMaps) < 50) {
    this.cleanupSuggestion.totalCheckedMaps += " Het aantal mappen is relatief klein. Controleer of alles nog overzichtelijk en gestructureerd is.";
  }

  // Relative suggestions between totalCheckedDocs and totalCheckedMaps
  if (parseInt(this.totalCheckedDocs) > 10000 && parseInt(this.totalCheckedMaps) < 100) {
    this.cleanupSuggestion.totalCheckedDocs = "Je hebt heel veel documenten voor het aantal mappen. Overweeg om je documenten in meer submappen te organiseren.";
    if (this.TotalDocsDiff > 5) {
      this.cleanupSuggestion.totalCheckedDocs += ` Bovendien is het aantal documenten gegroeid met ${this.TotalDocsDiff}%. Dit kan extra behoefte creëren aan verdere organisatie in submappen.`;
    } else if (this.TotalDocsDiff < -5) {
      this.cleanupSuggestion.totalCheckedDocs += ` Het aantal documenten is echter afgenomen met ${Math.abs(this.TotalDocsDiff)}%, wat goed is. Zorg ervoor dat je ruimte blijft vrijhouden.`;
    } else if (this.TotalDocsDiff !== 0) {
      this.cleanupSuggestion.totalCheckedDocs += " Het aantal documenten is relatief stabiel gebleven sinds de laatste controle.";
    }
  } else if (parseInt(this.totalCheckedDocs) < 1000 && parseInt(this.totalCheckedMaps) > 500) {
    this.cleanupSuggestion.totalCheckedDocs = "Je hebt veel mappen met relatief weinig documenten. Misschien kun je sommige mappen samenvoegen om ze overzichtelijker te maken.";
    if (this.TotalDocsDiff > 5) {
      this.cleanupSuggestion.totalCheckedDocs += ` Het aantal documenten is recentelijk gegroeid met ${this.TotalDocsDiff}%, dus mogelijk moet je ook je mapstructuur heroverwegen.`;
    } else if (this.TotalDocsDiff < -5) {
      this.cleanupSuggestion.totalCheckedDocs += ` Interessant genoeg is het aantal documenten afgenomen met ${Math.abs(this.TotalDocsDiff)}%, waardoor het samenvoegen van mappen een goede optie blijft.`;
    } else if (this.TotalDocsDiff !== 0) {
      this.cleanupSuggestion.totalCheckedDocs += " Het aantal documenten is stabiel gebleven sinds de laatste controle.";
    }
  } else {
    this.cleanupSuggestion.totalCheckedDocs = "Het aantal documenten en mappen lijkt in balans, maar blijf regelmatig je structuur controleren.";
    if (this.TotalDocsDiff > 5) {
      this.cleanupSuggestion.totalCheckedDocs += ` Houd er rekening mee dat je aantal documenten is gegroeid met ${this.TotalDocsDiff}%.`;
    } else if (this.TotalDocsDiff < -5) {
      this.cleanupSuggestion.totalCheckedDocs += ` Het aantal documenten is afgenomen met ${Math.abs(this.TotalDocsDiff)}%, wat aangeeft dat je opruiming effect heeft.`;
    } else if (this.TotalDocsDiff !== 0) {
      this.cleanupSuggestion.totalCheckedDocs += " Het aantal documenten is stabiel gebleven sinds de laatste controle.";
    }
  }

  // Suggestions based on identical documents
  if (parseInt(this.totalIDDocs) > 500) {
    this.cleanupSuggestion.totalIDDocs = "Er zijn veel identieke documenten. Overweeg om dubbele bestanden te verwijderen of samen te voegen.";
  } else if (parseInt(this.totalIDDocs) > 100 && parseInt(this.totalCheckedDocs) > 5000) {
    this.cleanupSuggestion.totalIDDocs = "Hoewel je relatief veel documenten hebt, is het aantal identieke documenten beheersbaar. Overweeg om periodiek te controleren op duplicaten.";
  } else {
    this.cleanupSuggestion.totalIDDocs = "Het aantal identieke documenten is klein. Goed gedaan!";
  }

  // Add suggestions based on IDDocContentDiff
  if (this.IDDocContentDiff > 5) {
    this.cleanupSuggestion.totalIDDocs += " Het aantal identieke documenten is toegenomen sinds de laatste controle. Overweeg om regelmatig te controleren op dubbele bestanden.";
  } else if (this.IDDocContentDiff < -5) {
    this.cleanupSuggestion.totalIDDocs += " Het aantal identieke documenten is afgenomen. Goed bezig met het opschonen van duplicaten!";
  } else if (this.IDDocContentDiff !== 0) {
    this.cleanupSuggestion.totalIDDocs += " Het aantal identieke documenten is stabiel gebleven sinds de laatste controle.";
  }

  // Suggestions for files with identical names
  if (parseInt(this.totalIDNameDocs) > 200) {
    this.cleanupSuggestion.totalIDNameDocs = "Je hebt veel bestanden met dezelfde naam. Dit kan verwarrend zijn. Overweeg om deze bestanden te hernoemen of te organiseren in submappen.";
  } else {
    this.cleanupSuggestion.totalIDNameDocs = "Er zijn niet veel bestanden met identieke namen. Zorg er wel voor dat je regelmatig controleert of bestandsnamen duidelijk zijn.";
  }

  // Add suggestions based on IDDocNameDiff
  if (this.IDDocNameDiff > 5) {
    this.cleanupSuggestion.totalIDNameDocs += " Het aantal bestanden met identieke namen is toegenomen. Dit kan de overzichtelijkheid beïnvloeden, overweeg om bestandsnamen duidelijker te maken.";
  } else if (this.IDDocNameDiff < -5) {
    this.cleanupSuggestion.totalIDNameDocs += " Het aantal bestanden met identieke namen is afgenomen. Goed bezig!";
  } else if (this.IDDocNameDiff !== 0) {
    this.cleanupSuggestion.totalIDNameDocs += " Het aantal bestanden met identieke namen is stabiel sinds de laatste controle.";
  }

  // Suggestions for documents with identical content and name
  if (this.completelyIDDocs.length > 50) {
    this.cleanupSuggestion.completelyIDDocs = "Je hebt veel bestanden met zowel identieke namen als inhoud. Overweeg deze bestanden te consolideren om schijfruimte te besparen.";
  } else {
    this.cleanupSuggestion.completelyIDDocs = "Het aantal bestanden met identieke inhoud en naam is beheersbaar. Blijf regelmatig controleren op duplicaten.";
  }

  // Add suggestions based on CompletelyIDDocsDiff
  if (this.CompletelyIDDocsDiff > 5) {
    this.cleanupSuggestion.completelyIDDocs += " Het aantal bestanden met zowel identieke inhoud als naam is toegenomen. Dit kan schijfruimte verspillen, overweeg om dubbele bestanden samen te voegen.";
  } else if (this.CompletelyIDDocsDiff < -5) {
    this.cleanupSuggestion.completelyIDDocs += " Het aantal bestanden met zowel identieke inhoud als naam is afgenomen. Goed werk!";
  } else if (this.CompletelyIDDocsDiff !== 0) {
    this.cleanupSuggestion.completelyIDDocs += " Het aantal bestanden met zowel identieke inhoud als naam is stabiel gebleven.";
  }

  // Suggestions for corrupt documents
  if (parseInt(this.totalCorruptDocs) > 50) {
    this.cleanupSuggestion.totalCorruptDocs = "Er zijn veel corrupte documenten gedetecteerd. Probeer deze te repareren of te verwijderen om problemen te voorkomen.";
  } else if (parseInt(this.totalCorruptDocs) > 0) {
    this.cleanupSuggestion.totalCorruptDocs = "Er zijn enkele corrupte documenten gevonden. Controleer of ze hersteld kunnen worden.";
  } else {
    this.cleanupSuggestion.totalCorruptDocs = "Geen corrupte documenten gevonden. Goed gedaan!";
  }

  // Add suggestions based on CorruptDocsDiff
  if (this.CorruptDocsDiff > 5) {
    this.cleanupSuggestion.totalCorruptDocs += " Het aantal corrupte documenten is gestegen sinds de laatste controle. Dit kan wijzen op een probleem met bepaalde bestanden. Controleer of deze documenten hersteld kunnen worden of verwijder ze om problemen te voorkomen.";
  } else if (this.CorruptDocsDiff < -5) {
    this.cleanupSuggestion.totalCorruptDocs += " Het aantal corrupte documenten is afgenomen. Goed werk! Blijf je bestanden regelmatig controleren om problemen te voorkomen.";
  } else if (this.CorruptDocsDiff !== 0) {
    this.cleanupSuggestion.totalCorruptDocs += " Het aantal corrupte documenten is stabiel gebleven sinds de laatste controle.";
  }

  // Suggestions for empty maps
  if (parseInt(this.totalEmptyMaps) > 100) {
    this.cleanupSuggestion.totalEmptyMaps = "Er zijn veel lege mappen. Overweeg deze mappen te verwijderen om je bestandssysteem overzichtelijker te maken.";
  } else if (parseInt(this.totalEmptyMaps) > 0) {
    this.cleanupSuggestion.totalEmptyMaps = "Enkele lege mappen gevonden. Misschien wil je ze verwijderen om het systeem op te ruimen.";
  } else {
    this.cleanupSuggestion.totalEmptyMaps = "Geen lege mappen gevonden. Je bestandssysteem is goed georganiseerd.";
  }

  // Add suggestions based on EmptyMapsDiff
  if (this.EmptyMapsDiff > 5) {
    this.cleanupSuggestion.totalEmptyMaps += " Het aantal lege mappen is toegenomen. Dit kan je bestandssysteem onoverzichtelijk maken, dus overweeg om ongebruikte mappen te verwijderen.";
  } else if (this.EmptyMapsDiff < -5) {
    this.cleanupSuggestion.totalEmptyMaps += " Het aantal lege mappen is afgenomen. Goed werk! Het helpt om je systeem netjes te houden.";
  } else if (this.EmptyMapsDiff !== 0) {
    this.cleanupSuggestion.totalEmptyMaps += " Het aantal lege mappen is stabiel gebleven sinds de laatste controle.";
  }

  // Suggestions for files with the same name but different formats
  if (parseInt(this.totalIDDocsDifferentFormat) > 50) {
    this.cleanupSuggestion.totalIDDocsDifferentFormat = "Je hebt veel bestanden met dezelfde naam maar een ander formaat. Dit kan voor verwarring zorgen. Overweeg om deze bestanden te controleren en mogelijk samen te voegen of te hernoemen.";
  } else {
    this.cleanupSuggestion.totalIDDocsDifferentFormat = "Het aantal bestanden met identieke namen maar verschillende formaten is laag. Alles lijkt in orde!";
  }

  // Add suggestions based on IDDocDiffFormatDiff
  if (this.IDDocDiffFormatDiff > 5) {
    this.cleanupSuggestion.totalIDDocsDifferentFormat += " Het aantal bestanden met identieke namen en verschillende formaten is toegenomen. Dit kan leiden tot verwarring over welke versie de juiste is. Overweeg om deze bestanden te controleren en mogelijk samen te voegen.";
  } else if (this.IDDocDiffFormatDiff < -5) {
    this.cleanupSuggestion.totalIDDocsDifferentFormat += " Het aantal bestanden met identieke namen en verschillende formaten is afgenomen. Goed bezig!";
  } else if (this.IDDocDiffFormatDiff !== 0) {
    this.cleanupSuggestion.totalIDDocsDifferentFormat += " Het aantal bestanden met identieke namen en verschillende formaten is stabiel gebleven sinds de laatste controle.";
  }

  // Suggestions for wrong file extensions
  if (parseInt(this.totalWrongExtension) > 100) {
    this.cleanupSuggestion.totalWrongExtension = "Er zijn veel bestanden met foutieve extensies. Controleer deze bestanden om er zeker van te zijn dat ze correct geassocieerd zijn met het juiste programma.";
  } else if (parseInt(this.totalWrongExtension) > 0) {
    this.cleanupSuggestion.totalWrongExtension = "Er zijn enkele bestanden met foutieve extensies. Het is aanbevolen om deze te corrigeren.";
  } else {
    this.cleanupSuggestion.totalWrongExtension = "Geen bestanden met foutieve extensies gevonden. Goed werk!";
  }

  // Add suggestions based on WrongExtensionsDiff
  if (this.WrongExtensionDiff > 5) {
    this.cleanupSuggestion.totalWrongExtension += " Het aantal bestanden met foutieve extensies is gestegen. Controleer deze bestanden om te zorgen dat ze geassocieerd zijn met het juiste programma en niet per ongeluk verkeerd geclassificeerd zijn.";
  } else if (this.WrongExtensionDiff < -5) {
    this.cleanupSuggestion.totalWrongExtension += " Het aantal bestanden met foutieve extensies is afgenomen. Goed werk! Blijf je systeem controleren om ervoor te zorgen dat bestanden correct blijven geclassificeerd.";
  } else if (this.WrongExtensionDiff !== 0) {
    this.cleanupSuggestion.totalWrongExtension += " Het aantal bestanden met foutieve extensies is stabiel gebleven sinds de laatste controle.";
  }

  // Suggestions for password-protected documents
  if (parseInt(this.totalPasswordProtected) > 50) {
    this.cleanupSuggestion.totalPasswordProtected = "Er zijn veel met wachtwoord beveiligde bestanden. Zorg ervoor dat je wachtwoorden veilig bewaart en dat deze bestanden correct worden gebruikt.";
  } else if (parseInt(this.totalPasswordProtected) > 0) {
    this.cleanupSuggestion.totalPasswordProtected = "Enkele met wachtwoord beveiligde bestanden gevonden. Zorg ervoor dat je deze wachtwoorden veilig bewaart.";
  } else {
    this.cleanupSuggestion.totalPasswordProtected = "Geen met wachtwoord beveiligde bestanden gevonden. Alles is toegankelijk!";
  }

  // Add suggestions based on ProtectedwPassDiff
  if (this.ProtectedwPassDiff > 5) {
    this.cleanupSuggestion.totalPasswordProtected += " Het aantal met wachtwoord beveiligde bestanden is gestegen. Dit kan wijzen op gevoelige informatie. Zorg ervoor dat de wachtwoorden veilig zijn opgeslagen en dat ze correct worden toegepast.";
  } else if (this.ProtectedwPassDiff < -5) {
    this.cleanupSuggestion.totalPasswordProtected += " Het aantal met wachtwoord beveiligde bestanden is afgenomen. Goed bezig! Zorg ervoor dat je wachtwoorden veilig blijven.";
  } else if (this.ProtectedwPassDiff !== 0) {
    this.cleanupSuggestion.totalPasswordProtected += " Het aantal met wachtwoord beveiligde bestanden is stabiel gebleven sinds de laatste controle.";
  }

  // Suggestions for invalid shortcuts
  if (parseInt(this.totalWrongShortcuts) > 50) {
    this.cleanupSuggestion.totalWrongShortcuts = "Er zijn veel ongeldige snelkoppelingen. Overweeg om deze te verwijderen om je systeem schoon te houden.";
  } else if (parseInt(this.totalWrongShortcuts) > 0) {
    this.cleanupSuggestion.totalWrongShortcuts = "Enkele ongeldige snelkoppelingen gevonden. Misschien wil je deze opruimen.";
  } else {
    this.cleanupSuggestion.totalWrongShortcuts = "Geen ongeldige snelkoppelingen gevonden. Je systeem is netjes!";
  }

  // Add suggestions based on WrongShortcutsDiff
  if (this.WrongShortcutsDiff > 5) {
    this.cleanupSuggestion.totalWrongShortcuts += " Het aantal ongeldige snelkoppelingen is toegenomen. Dit kan leiden tot verwarring of onnodige rommel op je systeem. Overweeg om deze snelkoppelingen op te ruimen.";
  } else if (this.WrongShortcutsDiff < -5) {
    this.cleanupSuggestion.totalWrongShortcuts += " Het aantal ongeldige snelkoppelingen is afgenomen. Goed gedaan! Blijf je systeem controleren op overbodige snelkoppelingen.";
  } else if (this.WrongShortcutsDiff !== 0) {
    this.cleanupSuggestion.totalWrongShortcuts += " Het aantal ongeldige snelkoppelingen is stabiel gebleven sinds de laatste controle.";
  }
},
    generatePercentageDifferences(data){
      console.log(data);
      console.log(typeof data.totaleOmvang);
      if (typeof data.totaleOmvang != "undefined"){
        const calcPercentageDiff = (oldValue, newValue) => {
          newValue = parseFloat(newValue, 10);

          if (oldValue === 0 && newValue === 0) {
            return 0; // No change if both are zero
          } else if (oldValue === 0) {
            return 100; // Consider as 100% increase if old value was zero
          }

          // Calculate the percentage difference
          const percentageDiff = ((newValue - oldValue) / Math.abs(oldValue)) * 100;

          // Return the result rounded to two decimal places
          return Math.round(percentageDiff * 100) / 100;
        };
        this.oldTotalSize=data.totaleOmvang;
        this.oldTotalDocs=data.aantalDocumenten;
        this.oldTotalMaps=data.totaalDoorzochteMappen;
        this.oldIDDocName=data.totalIDNameDocs;
        this.oldIDDocContent=data.totalIDDocs;
        this.oldCompletelyIDDocs=data.completelyIDDocsAmount;
        this.oldCorruptDocs=data.corruptFormat;
        this.oldEmptyMaps=data.legeMappen;
        this.oldIDDocDiffFormat=data.documentenMetzelfdeNaam;
        this.oldWrongExtension=data.wrongExtensionsAmount;
        this.oldWrongShortcuts=data.snelkoppelingenAmount;
        this.oldProtectedwPass=data.passwordCount;

        this.TotalSizeDiff = calcPercentageDiff(this.oldTotalSize, this.totalSizeAccurate);
        this.TotalDocsDiff = calcPercentageDiff(this.oldTotalDocs, this.totalCheckedDocs);
        this.TotalMapsDiff = calcPercentageDiff(this.oldTotalMaps, this.totalCheckedMaps);
        this.IDDocNameDiff = calcPercentageDiff(this.oldIDDocName, this.totalIDNameDocs);
        this.IDDocContentDiff = calcPercentageDiff(this.oldIDDocContent, this.totalIDDocs);
        // Note: completelyIDDocs.length should be handled appropriately in data to be used here
        this.CompletelyIDDocsDiff = calcPercentageDiff(this.oldCompletelyIDDocs, this.completelyIDDocs.length-1);
        this.CorruptDocsDiff = calcPercentageDiff(this.oldCorruptDocs, this.totalCorruptDocs);
        this.EmptyMapsDiff = calcPercentageDiff(this.oldEmptyMaps, this.totalEmptyMaps);
        this.IDDocDiffFormatDiff = calcPercentageDiff(this.oldIDDocDiffFormat, this.totalIDDocsDifferentFormat);
        this.WrongExtensionDiff = calcPercentageDiff(this.oldWrongExtension, this.totalWrongExtension);
        this.WrongShortcutsDiff = calcPercentageDiff(this.oldWrongShortcuts, this.totalWrongShortcuts);
        this.ProtectedwPassDiff = calcPercentageDiff(this.oldProtectedwPass, this.totalPasswordProtected);

        //console.log(this.TotalSizeDiff.toString());
    }
    },
    generateCleanMapsData(data){
      // Access the nested data within the first object of the array
      //console.log(data);
      this.cleanMapsObject = data;
      console.log(data);
      this.totalCorruptDocs = data['overzicht'][10][1];
      console.log(this.totalCorruptDocs);
      this.totalEmptyMaps = data['overzicht'][11][1];
      this.totalIDDocsDifferentFormat = data['overzicht'][12][1];
    },
    generateLastTextCardsData(data) {
      // Log the received data to see its structure
      //console.log(data);

      // Access 'SecureDocs.xlsx', 'FileFormats.xlsx', and 'Shortcuts.xlsx' by their filenames
      const secureDocsData = data.find(item => Object.keys(item)[0] === 'SecureDocs.xlsx');
      const fileFormatsData = data.find(item => Object.keys(item)[0] === 'FileFormats.xlsx');
      const shortcutsData = data.find(item => Object.keys(item)[0] === 'Shortcuts.xlsx');

      // Process 'SecureDocs.xlsx' data
      if (secureDocsData) {
        //console.log('Processing SecureDocs.xlsx');
        this.protectedObject = secureDocsData['SecureDocs.xlsx']; // Access data by the key
        let count = 0;
        if (this.protectedObject['internebeveiliging']) {
          this.protectedObject['internebeveiliging'].forEach(item => {
            if (item[2].includes("beveiligd (wachtwoord voor openen)")) {
              count++;
            }
          });
        }
        this.totalPasswordProtected = count;
      }

      // Process 'FileFormats.xlsx' data
      if (fileFormatsData) {
        //console.log('Processing FileFormats.xlsx');
        this.wrongExtensionsObject = fileFormatsData['FileFormats.xlsx'];
        if (this.wrongExtensionsObject['verkeerdeextensie']) {
          this.totalWrongExtension = this.wrongExtensionsObject['verkeerdeextensie'].length - 1;
        }
      }

      // Process 'Shortcuts.xlsx' data
      if (shortcutsData) {
        //console.log('Processing Shortcuts.xlsx');
        this.shortcutsObject = shortcutsData['Shortcuts.xlsx'];
        const overzicht = this.shortcutsObject['overzicht'];
        if (overzicht && overzicht.length > 6) {
          this.totalWrongShortcuts = overzicht[6][1];
        }
      }
    },
    handleSegmentClick(label) {
      if (label === 'Label for gedeeltelijkuitgepaktezipbes') {
        this.currentTableData = this.gedeeltelijkuitgepaktezipbes;
      } else if (label === 'Label for nietuitgepaktezipbestanden') {
        this.currentTableData = this.nietuitgepaktezipbestanden;
      } else {
        // Default to vollediguitgepaktezipbestand
        this.currentTableData = this.vollediguitgepaktezipbestand;
      }
    },
    generateZipData(data){
      console.log(data);
      // Access the nested data within the first object of the array
      // Ensure you are working with the correct item in the data array
      const item = data;
      console.log(item)

      // Retrieve the key for the first file in this item
      if(item){
      const firstFileName = Object.keys(item)[0];

      // Access the 'overzicht' array within this file
      const overzicht = item['overzicht'];
      console.log(overzicht);

      // Access the third element of the 'overzicht' array
      // and then its second property
      if (overzicht && overzicht.length > 2) {
          this.totalZipFiles = overzicht[4];
          this.totalUnpackedZips = overzicht[5];
          this.totalPartlyUnpackedZips = overzicht[7];
          this.totalPackedZips = overzicht[9];

    //       const pieChartData = {
    //         labels: [],
    //         datasets: [{
    //           backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', /* more colors as needed */],
    //           data: []
    //         }]
    //       };

    // // Access the nested data within the first object of the array
    // const nestedData = data[0][Object.keys(data[0])[0]]['overzicht'];
    this.vollediguitgepaktezipbestand = item[firstFileName]['vollediguitgepaktezipbestand'];
    this.gedeeltelijkuitgepaktezipbes = item[firstFileName]['gedeeltelijkuitgepaktezipbes'];
    this.nietuitgepaktezipbestanden = item[firstFileName]['nietuitgepaktezipbestanden'];
    this.currentTableData = this.vollediguitgepaktezipbestand;
    //console.log(this.vollediguitgepaktezipbestand);

    // Accessing the values using the find function
    this.totalUnpackedZips = this.findValueByKey(overzicht, "Aantal uitgepakte ZIP-bestanden:");
    this.totalPartlyUnpackedZips = this.findValueByKey(overzicht, "Aantal gedeeltelijk uitgepakte ZIP-bestanden:");
    this.totalPackedZips = this.findValueByKey(overzicht, "Totaal aantal ZIP-bestanden:");

    // Log the values to confirm
    //console.log(this.totalUnpackedZips);
    //console.log(this.totalPartlyUnpackedZips);
    //console.log(this.totalPackedZips);

    // this.totalUnpackedZips = overzicht[5][1];
    // this.totalPartlyUnpackedZips = overzicht[7][1];
    // //console.log(overzicht);
    // this.totalPackedZips = overzicht[9][1];

    this.pieData = {
      labels: ['Uitgepakte ZIPs', 'Gedeeltelijk uitgepakte ZIPs', 'Niet-uitgepakte ZIPs'],
      datasets: [{
        data: [this.totalUnpackedZips, this.totalPartlyUnpackedZips, this.totalPackedZips],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
      }]
    };

      } else {
          //console.log('Overzicht array is too short or does not exist');
      }
    }
    },
    generateDocTypeChart(data) {
      //console.log(data);
      // Create the structure for pie chart data
      const pieChartData = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [], // Add as many colors as you have data points
          hoverBackgroundColor: [] // Optionally, specify hover colors
        }],
      };

      // Access the nested 'overzicht' data
      
      const nestedData = data['overzicht'];
      //console.log(data);
      // Assume this.totalSize is needed as before
      this.totalSizeAccurate = nestedData[7][1];
      //console.log("total size:");
      //console.log(nestedData[7][1]);
      //this.totalSize = data[0][Object.keys(data[0])[0]]['overzicht'][7][1].slice(0,3) + " MB";
      this.totalSize = nestedData[7][1];
      // Filter out the unwanted items and sort by the count of documents in descending order
      const filteredData = nestedData
        .filter(item => 
          isFinite(item[1]) && 
          item[0].startsWith("Aantal documenten") && 
          item[0] !== "Aantal documenten '*'" &&
          !item[0].endsWith("'*'")
          )
        .sort((a, b) => b[1] - a[1]) // Sort by the count of documents
        .slice(0, 10); // Take only the top 10
      //console.log(filteredData);
      // Define an array of colors for the pie chart segments
      const backgroundColors = [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#4BC0C0',
      '#9966FF',
      '#FF9F40',
      '#4D5360',
      '#EA6B66',
      '#70AD47',
      '#A3A1FB',
      ];


      // Loop through the filteredData array and populate the pie chart data
      filteredData.forEach((item, index) => {
        // This pattern matches both "Aantal documenten van het type 'XYZ'" and "Aantal documenten 'XYZ'",
// capturing only the "XYZ" part for further processing.
const match = item[0].match(/Aantal documenten (van het type )?'(.+?)'/);

// If there's a match, use the captured group (the document type without the prefix and quotes).
// If there's no match (which should be rare, given your description), fall back to the original item.
const label = match ? match[2] : item[0];

pieChartData.labels.push(label); // Use the cleaned up label for your chart

        pieChartData.datasets[0].data.push(item[1]);
        pieChartData.datasets[0].backgroundColor.push(backgroundColors[index % backgroundColors.length]); // Cycle through colors if there are more data points than colors
      });

      //console.log("piechartdata: ");
      //console.log(pieChartData);

      // Update your component's pie chart data property
      this.documentPieData = pieChartData;

      const nestedAlternateData = data['alledocumenten'].slice(1);

      // Function to extract the file extension
      const extractExtension = (filename) => {
        const parts = filename.split('.');
        return parts.length > 1 ? parts[parts.length - 1] : null;
      };

      // Object to store the total size of each extension
      let extensionSizes = {};

      nestedAlternateData.forEach(file => {
        const filename = file[1]; // Assuming the filename is at index 1
        const sizeKB = file[3]; // Assuming the size in KB is at index 3
        const ext = extractExtension(filename);

        if (ext) {
          extensionSizes[ext] = (extensionSizes[ext] || 0) + sizeKB;
        }
      });

      // Convert to array and sort by size
      const sortedExtensions = Object.entries(extensionSizes)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10); // Take top 10

      // Split into labels and data for the chart
      const labels = sortedExtensions.map(ext => ext[0]);
      const alternateData = sortedExtensions.map(ext => ext[1]);

      // Create the chart data structure
      const chartData = {
        labels: labels,
        datasets: [{
          data: alternateData,
          backgroundColor: backgroundColors.slice(0, labels.length), // Use only as many colors as needed
          hoverBackgroundColor: backgroundColors.slice(0, labels.length)
        }]
      };

      // Update the chart data
      this.alternatePieData = chartData;

    },
    generateSystemFormatsChart(data) {
      // Access the nested 'overzicht' data
      console.log(data['systeembestanden']);
      const nestedData = data['systeembestanden'].slice(1);
        // Create the structure for pie chart data
        const systemFormatChartData = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                '#9966FF', '#FF9F40', '#4D5360', '#EA6B66',
                '#70AD47', '#A3A1FB'
              ],
              hoverBackgroundColor: [] // Optionally, specify hover colors
            }],
          };

          // Extracting file extensions and counting occurrences
          let formatCounts = {};
          nestedData.forEach(item => {
            const filePath = item[0];
            const extension = filePath.split('.').pop();
            if (extension) {
              formatCounts[extension] = (formatCounts[extension] || 0) + 1;
            }
          });

          // Sorting by count and taking the top 10
          const sortedFormats = Object.entries(formatCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10);

          // Preparing chart data
          systemFormatChartData.labels = sortedFormats.map(item => item[0]);
          systemFormatChartData.datasets[0].data = sortedFormats.map(item => item[1]);

          // Update the chart data property
          this.systemFormatsChartData = systemFormatChartData;
    },
    generateTemplateFormatsChart(data) {
      // Access the nested 'overzicht' data
      //console.log(data['sjabloonbestanden']);
      const nestedData = data['sjabloonbestanden'].slice(1);
        // Create the structure for pie chart data
        const templateFormatChartData = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                '#9966FF', '#FF9F40', '#4D5360', '#EA6B66',
                '#70AD47', '#A3A1FB'
              ],
              hoverBackgroundColor: [] // Optionally, specify hover colors
            }],
          };

          // Extracting file extensions and counting occurrences
          let formatCounts = {};
          nestedData.forEach(item => {
            const filePath = item[0];
            const extension = filePath.split('.').pop();
            if (extension) {
              formatCounts[extension] = (formatCounts[extension] || 0) + 1;
            }
          });

          // Sorting by count and taking the top 10
          const sortedFormats = Object.entries(formatCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10);

          // Preparing chart data
          templateFormatChartData.labels = sortedFormats.map(item => item[0]);
          templateFormatChartData.datasets[0].data = sortedFormats.map(item => item[1]);

          // Update the chart data property
          this.templateFormatsChartData = templateFormatChartData;
    },
    generateCompressionFormatsChart(data) {
      // Access the nested 'overzicht' data
      //console.log(data['compressiebestanden']);
      const nestedData = data['compressiebestanden'].slice(1);
        // Create the structure for pie chart data
        const compressionFormatChartData = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                '#9966FF', '#FF9F40', '#4D5360', '#EA6B66',
                '#70AD47', '#A3A1FB'
              ],
              hoverBackgroundColor: [] // Optionally, specify hover colors
            }],
          };

          // Extracting file extensions and counting occurrences
          let formatCounts = {};
          nestedData.forEach(item => {
            const filePath = item[0];
            const extension = filePath.split('.').pop();
            if (extension) {
              formatCounts[extension] = (formatCounts[extension] || 0) + 1;
            }
          });
          //console.log(nestedData);
          // Sorting by count and taking the top 10
          const sortedFormats = Object.entries(formatCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10);
          
          // Preparing chart data
          compressionFormatChartData.labels = sortedFormats.map(item => item[0]);
          compressionFormatChartData.datasets[0].data = sortedFormats.map(item => item[1]);
          //console.log(compressionFormatChartData);
          // Update the chart data property
          this.compressionFormatsChartData = compressionFormatChartData;
    },
    generateBackupFormatsChart(data) {
      // Access the nested 'overzicht' data
      //console.log(data['autosaveenbackupbestanden']);
      const nestedData = data['autosaveenbackupbestanden'].slice(1);
        // Create the structure for pie chart data
        const backupFormatChartData = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                '#9966FF', '#FF9F40', '#4D5360', '#EA6B66',
                '#70AD47', '#A3A1FB'
              ],
              hoverBackgroundColor: [] // Optionally, specify hover colors
            }],
          };

          // Extracting file extensions and counting occurrences
          let formatCounts = {};
          nestedData.forEach(item => {
            const filePath = item[0];
            const extension = filePath.split('.').pop();
            if (extension) {
              formatCounts[extension] = (formatCounts[extension] || 0) + 1;
            }
          });

          // Sorting by count and taking the top 10
          const sortedFormats = Object.entries(formatCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10);

          // Preparing chart data
          backupFormatChartData.labels = sortedFormats.map(item => item[0]);
          backupFormatChartData.datasets[0].data = sortedFormats.map(item => item[1]);

          // Update the chart data property
          this.backupFormatsChartData = backupFormatChartData;
    }
  },
  created() {
    this.fetchData(this.batchId);
  },
};
</script>

<style scoped>
.shareable-url-container {
  max-width: 600px; /* Limits the width to 600px */
  width: 100%;      /* Ensures responsiveness */
  margin-top: 20px; /* Adds some spacing from the top */
  margin-right: 0px;
  justify-content: end;
}
</style>