<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            {{ chartTitle }}
          </h6>
          <h2 class="text-blueGray-700 text-xl font-semibold">
            {{ currentChartSubtitle }}
            <i v-if="alternateChartSubtitle.length > 1" class="fa fa-refresh cursor-pointer" style="font-size: 0.8em;" @click="toggleChartData"></i>
          </h2>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto h-64"> <!-- Add a fixed height here -->
      <div v-if="loading">
        <Skeleton height="100%" /> <!-- Adjusted to take the full height -->
      </div>
      <div v-else>
        <pie-chart v-if="isDataAvailable" :data="currentChartData" :options="chartOptions" />
        <div v-else class="flex items-center justify-center h-full">
          <span class="text-center text-gray-500">
            Er zijn geen gegevens om te tonen<br/><br/><br/>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs';
import { Skeleton } from 'vue-loading-skeleton';
import 'vue-loading-skeleton/dist/style.css';

export default {
  components: {
    PieChart: Pie,
    Skeleton,
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({}),
    },
    chartTitle: {
      type: String,
      default: 'Pie Chart',
    },
    chartSubtitle: {
      type: String,
      default: 'Distribution',
    },
    alternateChartData: {
      type: Object,
      default: () => ({}),
    },
    alternateChartSubtitle: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAlternateData: false,
      isDataAvailable: false,
    };
  },
  computed: {
    currentChartData() {
      return this.isAlternateData ? this.alternateChartData : this.chartData;
    },
    currentChartSubtitle() {
      return this.isAlternateData ? this.alternateChartSubtitle : this.chartSubtitle;
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          position: 'bottom',
          labels: {
            fontColor: 'rgba(0,0,0,.4)',
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.labels[tooltipItem.index] || '';
              if (label) {
                label += ': ';
              }
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              if (this.isAlternateData) {
                const mbValue = (value / 1024).toFixed(2);
                label += `${mbValue} MB`;
              } else {
                label += value;
              }
              return label;
            },
          },
        },
      };
    },
  },
  mounted(){
    console.log(this.chartData);
    const hasValueGreaterThanZero = this.chartData.datasets.some(dataset =>
      dataset.data.some(value => parseFloat(value) > 0)
    );
    if (hasValueGreaterThanZero > 0){
      this.isDataAvailable = true;
    }
  },
  methods: {
    toggleChartData() {
      this.isAlternateData = !this.isAlternateData;
    },
  },
};
</script>